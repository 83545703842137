import _ from 'lodash';
import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import {
  Grid,
  FormHelperText,
  DialogContent,
  DialogActions,
  Box,
} from '@mui/material';
import { withStyles } from '@mui/styles';

// actions
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from '@tanstack/react-query';
import { withShowable } from '../../console/_global/lib/withShowable';
import {
  assignUser,
  createUser,
  fetchAgencies,
  fetchRoles,
  updateUser,
} from '../../api/apis';
// components
import { ScrollableMultiSelect } from '../Selects/MultiSelect/ScrollableMultiSelect';
import { CheckBox } from '../inputs/Checkbox';
import CbButton from '../Buttons/CbButton';
import { TextFieldBase } from '../inputs/TextFieldBase';
// platform helpers
import { delayedEvent } from '../../utils/eventUtils';
import {
  reduceForSelect,
  mapAgencyPersonas,
  getSelectItemAgency,
} from '../../console/admin/users/userUtils';
// reducers
import { useActor } from '../hooks/useActor';
import { PERSONA_TYPES } from '../../console/_statics/persona.statics';
import { usePersona } from '../hooks/usePersona';
import { withFormController } from '../hocs/forms';
import LanguageForm, {
  useLanguageFormState,
} from '../../i18n/forms/LanguageForm';
import { FormLanguageProvider } from '../../i18n/forms/FormLanguageProvider';
import { SimpleSelect as SimpleSelectBase } from '../inputs';
import { InputLabelBase } from '../inputs/InputLabelBase';
import { CARRIER_USERS_TABLE_ID } from '../tables/table_constants';
import { manageAPIError } from '../../utils';
import { REDACTED_FIELD } from '../../config/logrocket';

const Select = withShowable(ScrollableMultiSelect);
const HiddenCheckbox = withShowable(CheckBox);
const TextField = withFormController(TextFieldBase);
const SimpleSelect = withFormController(SimpleSelectBase);

const styles = ({ config }) => {
  return {
    addUser: {
      display: 'flex',
      justifyContent: 'space-evenly',
      margin: '0px 0px -20px 0px',
      fontFamily: config.fonts.stackSans,
      fontSize: config.textSizes.normal,
    },
    select: {
      '& .MuiButton-fullWidth': {
        maxWidth: '40rem',
      },
    },
  };
};

const deriveSchema = (languageFormSchema) =>
  Yup.object().shape({
    role: Yup.string().ensure().required().label('Role'),
    firstName: Yup.string().label('First Name').required(),
    lastName: Yup.string().label('Last Name').required(),
    email: Yup.string().label('Email').email().required(),
    phone: languageFormSchema.phone,
  });

const admins = [
  'Super Admin',
  'Power User',
  'Customer Success',
  'Biz Dev',
  'TSM',
  'Technician',
  'Head of Claims',
];
const accTypes = [
  PERSONA_TYPES.COWBELL,
  PERSONA_TYPES.CUSTOMER,
  PERSONA_TYPES.DIGITAL,
  PERSONA_TYPES.MSSP,
  PERSONA_TYPES.CARRIER,
  PERSONA_TYPES.VENDOR,
];

export const UsersCreateEdit = withStyles(styles)(
  ({ classes, close, data }) => {
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();
    const edit = !_.isEmpty(data);
    const languageFormState = useLanguageFormState({
      options: { validatePhone: true, phoneFieldName: 'phone' },
    });

    const actor = useActor();
    const persona = usePersona();

    const { handleSubmit, ...methods } = useForm({
      defaultValues: {
        firstName: data.firstName || '',
        lastName: data.lastName || '',
        email: data.email || '',
        role: data.role ? data.role.id : '',
        primaryContact: data.primaryContact || false,
        phone: data.phone || '',
        billingContact: data.billingContact || false,
        agencies: reduceForSelect(
          mapAgencyPersonas(_.get(data, 'userAccountsAccess'))
        ),
        slackHandle: data.slackHandle,
      },
      resolver: yupResolver(deriveSchema(languageFormState.languageFormSchema)),
    });

    methods.watch(['primaryContact', 'billingContact', 'role']);

    const [totalPages, setTotal] = useState(1);
    const [rolesList, setRoles] = useState([]);
    const [agencyList, setAgencies] = useState([]);
    const [admin, setAdmin] = useState(false);
    const [error, setError] = useState('');

    // eslint-disable-next-line
    const [id, setId] = useState('');

    const values = methods.getValues();

    useEffect(() => {
      let array = [];

      if (persona.isCowbell) {
        const promises = [
          fetchRoles(actor.account.accountType),
          fetchAgencies(),
        ];
        array = promises;
      } else {
        const promises = [fetchRoles(actor.account.accountType)];
        array = promises;
      }

      if (edit) {
        makeAdmin(values.role);
      }

      Promise.all(array).then((resp) => {
        setRoles(reduceForSelect(_.get(resp, '[0].data')));

        if (persona.isCowbell) {
          setTotal(_.get(resp, '[1].data.totalPages'));
          setAgencies(
            reduceForSelect(resp?.[1]?.data?.content, getSelectItemAgency)
          );
        } else if (
          persona.isInsured ||
          persona.isMssp ||
          persona.isCarrier ||
          persona.isVendor
        ) {
          setId(_.get(resp, '[1].data.id'));
        }

        if (edit && !accTypes.includes(actor.account.accountType)) {
          const roles = reduceForSelect([_.get(resp, '[2].data.role')]);
          methods.setValue('role', roles);

          roles.map((post) => {
            if (post.label === 'Role Undefined') {
              setAdmin(false);
            } else {
              makeAdmin(post);
            }
            return 0;
          });
          setId(_.get(resp, '[2].data.id'));
        }
      });
      // eslint-disable-next-line
    }, []);

    const handleCheckboxChange = (event) => {
      methods.setValue(event.target.name, event.target.checked);
    };

    const makeAdmin = (post) => {
      setAdmin(admins.includes(post.label));
    };

    const handleSelect = (name, selection) => {
      methods.setValue(name, selection);
      if (name === 'role') {
        makeAdmin(selection);
      }
    };

    const onSubmit = ({ agencies, role, ...formValues }) => {
      const payload = {
        roleId: values.role,
        ...formValues,
      };
      if (!admin) {
        const array = [];
        if (persona.isCowbell && agencies) {
          agencies.map((post) => {
            array.push(post.value);
            return array;
          });
        }

        if (edit && persona.isCowbell) {
          assignUser(payload.email, { accountIds: array }).catch(() => {
            setError('Not able to assign agencies at this time.');
          });
        }
        payload.accountIds = array;
      }

      const saveRequest = edit
        ? updateUser(data.id, payload)
        : createUser(payload);

      return saveRequest
        .then(() => {
          const message = edit ? (
            <span className={REDACTED_FIELD}>
              User {formValues.firstName} {formValues.lastName} updated
              successfully!
            </span>
          ) : (
            <span className={REDACTED_FIELD}>
              User {formValues.firstName} {formValues.lastName} created
              successfully and invited to login!
            </span>
          );
          enqueueSnackbar(message, { variant: 'success' });
          close();
          delayedEvent('table-refetch', 500, 'users');
          queryClient.invalidateQueries([CARRIER_USERS_TABLE_ID]);
        })
        .catch((error) => {
          const errorMsg = manageAPIError(
            error,
            'Something went wrong. Please try again later'
          );

          setError(errorMsg);
        });
    };

    return (
      <FormLanguageProvider {...languageFormState}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent style={{ padding: '0 3rem 3rem' }}>
              <Grid container spacing={2}>
                <Grid item md={6} style={{ paddingTop: 0 }}>
                  <TextField
                    label="First Name"
                    placeholder="First Name"
                    required
                    name="firstName"
                  />
                </Grid>
                <Grid item md={6} style={{ paddingTop: 0 }}>
                  <TextField
                    required
                    label="Last Name"
                    placeholder="Last Name"
                    name="lastName"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item md={6}>
                  <TextField required label="Email" type="email" name="email" />
                </Grid>
                <Grid item md={6}>
                  <Box mt="0.5rem">
                    <LanguageForm.PhoneField
                      name="phone"
                      label="Contact Number"
                    />
                  </Box>
                  {/* <PhoneField
                    name="phone"
                    label="Contact Number"
                    required
                    placeholder="(___)___-____"
                    data-qa="phone"
                  /> */}
                </Grid>
              </Grid>
              <Grid item>
                <InputLabelBase
                  indent
                  style={{ paddingTop: 0, paddingBottom: '0.5rem' }}
                  required
                >
                  Role
                </InputLabelBase>
                <SimpleSelect
                  name="role"
                  options={rolesList}
                  controllerProps={{ control: methods.control }}
                  required
                />
              </Grid>
              <Grid item className={`${classes.select} ${REDACTED_FIELD}`}>
                <Select
                  show={
                    ![
                      PERSONA_TYPES.CARRIER,
                      PERSONA_TYPES.RE_INSURER,
                      PERSONA_TYPES.PREMIUM_PROCESSOR,
                      PERSONA_TYPES.CUSTOMER,
                    ].includes(persona.type) &&
                    (!actor.isCowbell || !persona.isCowbell)
                  }
                  name="agencies"
                  label="Agencies"
                  {...methods.register('agencies')}
                  buttonPlaceholder="Assign Agencies"
                  onChange={handleSelect}
                  values={values.agencies}
                  options={agencyList}
                  api={fetchAgencies}
                  totalPages={totalPages}
                  reduceFunc={reduceForSelect}
                />
              </Grid>

              {persona.isCowbell && (
                <Grid container spacing={6}>
                  <Grid item md={12}>
                    <InputLabelBase indent>Slack Handle</InputLabelBase>
                    <TextField placeholder="Slack Handle" name="slackHandle" />
                  </Grid>
                </Grid>
              )}

              {!persona.isCarrier && (
                <div className={classes.addUser}>
                  <HiddenCheckbox
                    {...methods.register('primaryContact')}
                    name="primaryContact"
                    defaultChecked={false}
                    label="Primary Contact"
                    checked={values.primaryContact}
                    onChange={handleCheckboxChange}
                    show={values.role.label !== 'Technician'}
                  />
                  {!persona.isMssp && !persona.isInsured && (
                    <HiddenCheckbox
                      name="billingContact"
                      defaultChecked={false}
                      checked={values.billingContact}
                      label="Billing Contact"
                      {...methods.register('billingContact')}
                      onChange={handleCheckboxChange}
                      show={values.role.label !== 'Technician'}
                    />
                  )}
                </div>
              )}
              <FormHelperText className="api-text-error">
                {error}
              </FormHelperText>
            </DialogContent>
            <DialogActions>
              <CbButton
                styleName="cancel"
                onClick={close}
                buttonText="Cancel"
              />
              <CbButton
                type="submit"
                styleName="ctaButton"
                buttonText={edit ? 'Update Details' : 'Save Details'}
                loading={methods.formState.isSubmitting}
                disabled={methods.formState.isSubmitting}
              />
            </DialogActions>
          </form>
        </FormProvider>
      </FormLanguageProvider>
    );
  }
);

export default UsersCreateEdit;
