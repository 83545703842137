import {
  ServiceHandler,
  applyQueryConfig,
  responseStringTransformer,
} from '../utils';
import { standardQuerySerializer } from './serializers';

import { policyServiceMappings } from '../policies/policyServiceMappings';

import {
  pullFactorsFromCowbellRiskFactors,
  serializeCowbellFactors,
} from './serializers/cowbell-factors.serializers';
import type { ProductType } from '../types';
import { ProductTypes } from '../types';
import { transformPrimeXPolicyDetailsResponse } from './serializers/policy.serializers';
import type {
  Diff,
  ESPolicyPx,
  GetPrimeXPolicyDetailsByViewResponse,
  PolicyPg,
  PolicyRevisionResp,
} from './policies.api.types';
import type { Policy } from '../console/accounts/summary/types/policy.types';
import type { PageableResponse } from './serializers';
import type { PolicyTableInfoDto } from '../types/policy/tables/policy-table-info.dto';
import { getData } from '../utils/functional/fp';
import {
  industryCodeSerializer,
  serializeLegacyIndustryCode,
  serializePxQuoteIndustryCode,
} from './serializers/industry-code.serializers';
import type { Progress } from '../console/workflows/policies/prime-x/endorsements/api/types';

type SearchPoliciesResponse = PageableResponse<PolicyTableInfoDto>;
export const searchPolicies = ServiceHandler<unknown, SearchPoliciesResponse>({
  url: '/api/policy/v1/search',
  paramsSerializer: (params) =>
    standardQuerySerializer(policySearchQuerySerializer(params)),
});

function policySearchQuerySerializer(params: any) {
  return applyQueryConfig(policyServiceMappings, params);
}

export const searchBORPolicies = ServiceHandler<
  unknown,
  SearchPoliciesResponse
>({
  url: '/api/policy/v1/search/bor-policies',
  paramsSerializer: (params) =>
    standardQuerySerializer(policySearchQuerySerializer(params)),
});

export const getPolicyDetailsP100 = ServiceHandler({
  url: '/api/policy/v1/:policyId',
  transformResponse: [
    responseStringTransformer,
    pullFactorsFromCowbellRiskFactors,
    serializeCowbellFactors,
    serializeLegacyIndustryCode,
  ],
});

export const fetchPolicyDetailsP100 = ServiceHandler({
  url: '/api/policy/v1/:policyId',
  transformResponse: [
    responseStringTransformer,
    pullFactorsFromCowbellRiskFactors,
    serializeCowbellFactors,
    getData,
  ],
});

export const getPolicyDetailsP250 = ServiceHandler({
  url: '/api/policy/v1/P250/:policyId',
  transformResponse: [
    responseStringTransformer,
    pullFactorsFromCowbellRiskFactors,
    serializeCowbellFactors,
    serializeLegacyIndustryCode,
  ],
});

export const updatePolicyBilling = ServiceHandler({
  url: '/api/policy/v1/billing-updated',
  method: 'put',
});

export const getPolicyDetails = (id: string, product: string) => {
  switch (product) {
    case ProductTypes.p100:
      return getPolicyDetailsP100(id);
    case ProductTypes.p250:
      return getPolicyDetailsP250(id);
    case ProductTypes.primecloud:
      return getPolicyDetailsPrimeCloud(id);
    default:
      return getFullPrimeXPolicyDetails(product, id);
  }
};

export const getPrimeXPaymentDetails = ServiceHandler({
  url: '/api/policy/v1/:productType/:policyId?view=standard',
  transformResponse: [
    responseStringTransformer,
    transformPrimeXPolicyDetailsResponse,
  ],
});

export const getFullPrimeXPolicyDetails = ServiceHandler({
  url: '/api/policy/v1/:productType/:policyId?view=full',
  transformResponse: [
    responseStringTransformer,
    transformPrimeXPolicyDetailsResponse,
    pullFactorsFromCowbellRiskFactors,
    serializeCowbellFactors,
    serializePxQuoteIndustryCode,
  ],
});

export const getPolicyDetailsPrimeCloud = ServiceHandler({
  url: '/api/policy/v1/primecloud/details/:policyId',
  transformResponse: [
    responseStringTransformer,
    pullFactorsFromCowbellRiskFactors,
    serializeCowbellFactors,
  ],
});

export const createPrimecloudPolicy = ServiceHandler({
  url: '/api/policy/v1/primecloud',
  method: 'POST',
});

export const updatePrimecloudPolicy = ServiceHandler({
  url: '/api/policy/v1/primecloud/:policyId',
  method: 'PUT',
});

export const updatePrimecloudPolicyDoc = ServiceHandler({
  url: '/api/policy/v1/primecloud/policy-doc-upload-confirm/:policyId',
  method: 'POST',
});

export const reinstatePolicy = ServiceHandler({
  url: '/api/policy/v1/noc/reinstate/:policyId',
  method: 'POST',
});

export const bulkRenewalProcessToggle = ServiceHandler({
  url: '/api/policy/v1/bulk/renewal-opt-out',
  method: 'PUT',
});

export interface GetPoliciesEarnedPremiumGraphResponse {
  hits: {
    total: number;
  };
  aggregations: {
    'time range': {
      doc_count: number;
      'Event distribution by time': {
        buckets: {
          key_as_string: number;
          key: number;
          doc_count: number;
          TotalEarnedPremium: {
            value: number;
          };
          EarnedPremium: {
            value: number;
          };
        }[];
      };
    };
  };
}

export const POLICIES_EARNED_PREMIUM_GRAPH = 'POLICIES_EARNED_PREMIUM_GRAPH';

export const getPoliciesEarnedPremiumGraph = ServiceHandler<
  unknown,
  GetPoliciesEarnedPremiumGraphResponse
>({
  url: 'api/policy/v1/ep/graph',
  paramsSerializer: (params) =>
    standardQuerySerializer(policySearchQuerySerializer(params)),
});

export const rescindExcessBinder = ServiceHandler({
  url: '/api/policy/v1/P250/excess/rescind/:policyId',
  method: 'POST',
});

interface regenerateProposal {
  sendEmail: boolean;
}

export const regeneratePolicyInvoice = ServiceHandler<
  regenerateProposal,
  unknown
>({
  url: '/api/policy/v1/:productType/:policyId/regenerate-invoice',
  method: 'PUT',
});

export const getPrimeXPolicyDetailsByView = ServiceHandler<
  unknown,
  GetPrimeXPolicyDetailsByViewResponse
>({
  url: '/api/policy/v1/:productType/:policyId',
  transformResponse: [
    responseStringTransformer,
    transformPrimeXPolicyDetailsResponse,
  ],
});

export const generateRenewalQuote = ServiceHandler({
  url: '/api/policy/v1/trigger/auto-renewal-quote/:productType/:policyId',
  method: 'POST',
});

export const fetchLatestPolicyData = ServiceHandler<unknown, Policy>({
  url: 'api/policy/v1/:product/endorsement/latest/:policyNumber',
  method: 'get',
});

export const undoCancelledP100Policy = ServiceHandler({
  url: '/api/policy/v1/undo-cancelled-policy/:policyId',
  method: 'PUT',
});

// * product agnostic -- supports P1P and P250 as of 9/12/2023
export const undoCancelledPolicy = ServiceHandler({
  url: '/api/policy/v1/:product/undo-cancelled-policy/:policyId',
  method: 'PUT',
});

export const undoPolicyCancellation = ({
  product,
  id,
}: {
  product: ProductType;
  id: string;
}) => {
  if (product == ProductTypes.p100) {
    return undoCancelledP100Policy(id);
  }

  return undoCancelledPolicy(product, id);
};

export const getLatestPolicyByAccountId = ServiceHandler({
  url: '/api/policy/v1/P250/latest-policy/:accountId',
});

export const bulkUpdateProgramPolicies = ServiceHandler({
  url: '/api/policy/v1/:productType/bulk/add/program',
  method: 'PUT',
});

export const deleteProgramFromPolicy = ServiceHandler({
  url: '/api/policy/v1/:productType/:policyId/program',
  method: 'DELETE',
});

export const generatePrimePlusPolicy = ServiceHandler({
  url: '/api/policy/v1/P250/:accountId',
  method: 'POST',
});

export const generatePrimeTechPlusPolicy = ServiceHandler({
  url: '/api/policy/v1/PRIME_TECH_PLUS/adhoc-excess/:accountId',
  method: 'POST',
});

export const generatePonePlusPolicy = ServiceHandler({
  url: '/api/policy/v1/P_ONE_PLUS/adhoc-excess/:accountId',
  method: 'POST',
});

export const issuePrimeTechPlusPolicy = ServiceHandler({
  url: '/api/policy/v1/:productType/adhoc-excess/issue/:policyId',
  method: 'PUT',
});

export const issuePonePlusPolicy = ServiceHandler({
  url: '/api/policy/v1/P_ONE_PLUS/adhoc-excess/issue/:binderId',
  method: 'PUT',
});

/**
 * @function rescindExcessPolicyBinder
 * This function caters to following product type
 * ProductType.p_one_plus
 * ProductType.prime_tech_plus
 */
export const rescindExcessPolicyBinder = ServiceHandler({
  url: '/api/policy/v1/:productType/adhoc-excess/rescind/:policyId',
  method: 'PUT',
});

export const stopNOC = ServiceHandler({
  url: 'api/policy/v1/noc/stop/:policyId',
  method: 'PUT',
});

export const extendNOCCancellationTriggerDate = ServiceHandler({
  url: '/api/policy/v1/noc/extend-cancellation-trigger-date/:policyId',
  method: 'PUT',
});

export const togglePolicyDocs = ServiceHandler({
  url: '/api/policy/v1/:productType/:policyId/document',
  method: 'PUT',
});

export const getPgPxPolicy = ServiceHandler<unknown, PolicyPg>({
  url: '/api/policy/v1/details/number/:policyNumber',
});

export const getPrimeXPolicyDetails = ServiceHandler<unknown, ESPolicyPx>({
  url: `/api/policy/v1/:productType/:policyId?view=:view`,
  transformResponse: [responseStringTransformer, industryCodeSerializer],
});

export const getPxPolicyRevisions = ServiceHandler<unknown, PolicyRevisionResp>(
  {
    url: '/api/policy/v1/:policyNumber/revisions',
  }
);

export const getPolicyRevision = ServiceHandler<unknown, ESPolicyPx>({
  url: '/api/policy/v1/revision/:revisionId?view=:view',
});

export const getDiffByRevision = ServiceHandler<unknown, Diff<ESPolicyPx>>({
  url: '/api/policy/v1/revision/:curRevId/diff/:prevRevId',
});

export const getEndorsementPrgByRevision = ServiceHandler<unknown, Progress>({
  url: '/api/policy/v1/endorsement/progress/revision/:revisionId',
});

export const revertRevision = ServiceHandler<unknown, { revertReason: string }>(
  {
    url: '/api/policy/v1/revision/revert/:revisionId',
    method: 'PUT',
  }
);

export const regenerateRevisionDoc = ServiceHandler({
  url: '/api/policy/v1/endorsement/progress/regenerate_doc/:revisionId',
  method: 'PUT',
});
