/**
 * @name prime250Reducer
 * @description This reducer is a local reducer not intended to be used with redux or other global state mechanisms.
 */

import _ from 'lodash';
import { handleActions } from 'redux-actions';
import Moment from 'moment';
import { utcToNativeDate } from '../../utils/date.utils';
import type { Nullable } from '../../types/common/utility';
import type { AgencyStatus } from '../../types/agencies/dto/agency.dto';
import type { UiCoverageP250 } from '../../types';
import type { EmptyObject, SelectOption } from '../../types/components/common';

type Frequency =
  | 'SIX_MONTHS'
  | 'DAILY'
  | 'WEEKLY'
  | 'MONTHLY'
  | 'QUARTLERY'
  | 'NEVER';

type PremadePresetId = -1 | 1 | 2 | 3;
type CustomPresetId = string;
type PresetId = PremadePresetId | CustomPresetId;

type CoverageRetroActivePeriod = {
  asDate?: boolean;
  static?: boolean;
  synced?: boolean;
  disabled?: boolean;
  available: boolean;
  value: number;
};

export type Prime250Coverage = Omit<
  UiCoverageP250,
  'retroActivePeriod' | 'deductible' | 'limit' | 'waitingPeriod'
> & {
  retroActivePeriod: CoverageRetroActivePeriod;
  deductible: {
    available: boolean;
    min: Nullable<number>;
    value: Nullable<number>;
  };
  deductibleOptions: SelectOption[];
  limit: {
    alignMax: boolean;
    locked: boolean;
    min: Nullable<number>;
    max: Nullable<number>;
    step: Nullable<number>;
    value: Nullable<number>;
  };
  limitOptions: SelectOption[];
  waitingPeriod: {
    available: boolean;
    value: Nullable<number>;
  };
  waitingPeriodOptions: SelectOption[];
};

export type State = {
  account: {
    id: Nullable<string>;
    revenue: Nullable<number>;
    address1: Nullable<string>;
    address2: Nullable<string>;
    address3: Nullable<string>;
    city: Nullable<string>;
    state: Nullable<string>;
    zipCode: Nullable<string>;
    dunsNumber: Nullable<string>;
    industry: Nullable<string>;
    naicsCode: Nullable<number>;
    name: Nullable<string>;
    yearEstablished: Nullable<number>;

    factors: Nullable<Record<string, any>>;

    surplus?: Record<string, any>;
    surplusOnly: Nullable<boolean>;
    surplusAvailable: Nullable<boolean>;

    securityQuestions: {
      lastSecurityAnswered: Nullable<number>;
      dmzSeparation: Nullable<boolean>;
      thirdPartySecurityAgreement: Nullable<boolean>;
      // cyber crime loss
      isVerifyingBankAccounts: Nullable<boolean>;
      isAuthenticatingFundTransferRequests: Nullable<boolean>;
      isPreventingUnauthorizedWireTransfers: Nullable<boolean>;
      // full system failure
      backupFrequency: Frequency;
      testedFullFailover: Nullable<boolean>;
      // verification if questions were answered
      contingentSystemFailureAnswered: Nullable<boolean>;
      cyberCrimeLossAnswered: Nullable<boolean>;
      fullSystemFailureAnswered: Nullable<boolean>;
      // verification if questions were valid
      contingentSystemFailureValid: Nullable<boolean>;
      cyberCrimeLossValid: Nullable<boolean>;
      fullSystemFailureValid: Nullable<boolean>;
      // verification if questions are required
      contingentSystemFailureRequired: Nullable<boolean>;
      cyberCrimeLossRequired: Nullable<boolean>;
      fullSystemFailureRequired: Nullable<boolean>;
      civilOrCriminalAction: Nullable<boolean>;
      claimHistory: Nullable<number>;
      isSecurityOfficer: Nullable<boolean>;
      isSecurityTraining: Nullable<boolean>;
      lossInBusinessIncome: Nullable<boolean>;
      pastCyberIncident: Nullable<boolean>;
      patchingFrequency: Nullable<Frequency>;
      pendingLitigation: Nullable<boolean>;
      securityBreachRequiringNotification: Nullable<boolean>;
      useCloudStorage: Nullable<boolean>;
      useEncryption: Nullable<boolean>;
    };
  };

  coverages: Record<string, Prime250Coverage> | EmptyObject;

  // TODO: Investigate if this presets slice is in use -- it appears not to be
  presets: {
    basic: {
      coverages: [];
      premium: number;
    };

    popular: {
      coverages: [];
      premium: number;
    };

    elite: {
      coverages: [];
      premium: number;
    };
  };

  global: {
    deductible: number;
    limit: number;
    effectiveDate: Date;
    endDate: Date;
    maxCoverageEndDate: Date;
    minCoverageEndDate: Date;
    maxCoverageStartDate: Date;
    minCoverageStartDate: Date;
    minDate: Date;

    preset: PresetId;
    retroActivePeriod: number;
    retroActivePeriodAsDate: boolean;
    waitingPeriod: number;
  };

  ui: {
    agencyStatus: AgencyStatus | '';
    calculatorError: boolean;
    cartTotal: number;
    changeType: Nullable<boolean>;
    initialRender: boolean;
    limit: {
      min: number;
      max: number;
    };
    loading: boolean;
    premium: number;
    preset: number;
    renewal: boolean;
    shouldRecalculate: number;
    step: number;
    techENOProducts: string;
    techENOServices: string;
    uwReferral: boolean;
    isRenewal?: boolean;
    isMigration?: boolean;
    isEndorsement?: boolean;
  };
};

// actions
// account actions
export const SET_ACCOUNT = 'SET_ACCOUNT';
export const SET_SECURITY_QUESTIONS = 'SET_SECURITY_QUESTIONS';

// global actions
export const SET_GLOBAL_AND_RECALCULATE = 'SET_GLOBAL_AND_RECALCULATE';
export const SET_GLOBAL_DEDUCTIBLE = 'SET_GLOBAL_DEDUCTIBLE';
export const SET_GLOBAL_WAITING_PERIOD = 'SET_GLOBAL_WAITING_PERIOD';
export const SET_GLOBAL_RETRO_ACTIVE_PERIOD = 'SET_GLOBAL_RETRO_ACTIVE_PERIOD';
export const SET_GLOBAL_RETRO_ACTIVE_PERIOD_NO_SIDE_EFFECTS =
  'SET_GLOBAL_RETRO_ACTIVE_PERIOD_NO_SIDE_EFFECTS';
export const SET_GLOBAL_RETRO_ACTIVE_PERIOD_AS_DATE =
  'SET_GLOBAL_RETRO_ACTIVE_PERIOD_AS_DATE';
export const SET_GLOBAL_RETRO_ACTIVE_PERIOD_DATE_NO_SIDE_EFFECTS =
  'SET_GLOBAL_RETRO_ACTIVE_PERIOD_DATE_NO_SIDE_EFFECTS';
export const SET_GLOBAL_COVERAGE_PREMIUM = 'SET_GLOBAL_COVERAGE_PREMIUM';

// coverage actions
export const SET_COVERAGES = 'SET_COVERAGES';
export const SELECT_COVERAGE = 'SELECT_COVERAGE';
export const SET_COVERAGE_LIMIT = 'SET_COVERAGE_LIMIT';
export const SET_COVERAGE_DEDUCTIBLE = 'SET_COVERAGE_DEDUCTIBLE';
export const SET_COVERAGE_WAITING_PERIOD = 'SET_COVERAGE_WAITING_PERIOD';
export const SET_COVERAGE_RETRO_ACTIVE_PERIOD =
  'SET_COVERAGE_RETRO_ACTIVE_PERIOD';
export const SET_COVERAGE_RETRO_ACTIVE_PERIOD_DATE =
  'SET_COVERAGE_RETRO_ACTIVE_PERIOD_DATE';

// Request quote simple actions
export const SET_RECALCULATE = 'SET_RECALCULATE';

export const SET_GLOBAL = 'SET_GLOBAL';
export const SET_GLOBAL_PRESET = 'SET_GLOBAL_PRESET';
export const SET_UI = 'SET_UI';
export const SET_EFFECTIVE_DATE = 'SET_EFFECTIVE_DATE';

export const CUSTOM_PRESET_CHANGE_TYPE = 'CUSTOM_PRESET';
export const LIMIT_CHANGE_TYPE = 'LIMIT';
export const DEFAULT_CHANGE_TYPE = null;

// action creators
export const setPrime250Account = (payload: Partial<State['account']>) => ({
  type: SET_ACCOUNT,
  payload,
});

export const setPrime250SecurityQuestions = (
  payload: Partial<State['account']['securityQuestions']>
) => ({
  type: SET_SECURITY_QUESTIONS,
  payload,
});

export const setPrime250Global = (payload: Partial<State['global']>) => ({
  type: SET_GLOBAL,
  payload,
});

export const setPrime250EffectiveDate = (payload: { effectiveDate: Date }) => ({
  type: SET_EFFECTIVE_DATE,
  payload,
});

export const setPrime250GlobalAndRecalculate = (
  payload: Partial<State['global']>
) => ({
  type: SET_GLOBAL_AND_RECALCULATE,
  payload,
});

export const setPrime250GlobalPreset = (
  payload: State['global']['preset']
) => ({
  type: SET_GLOBAL_PRESET,
  payload,
});

export const setPrime250GlobalDeductible = (
  payload: State['global']['deductible']
) => ({
  type: SET_GLOBAL_DEDUCTIBLE,
  payload,
});

export const setPrime250GlobalWaitingPeriod = (
  payload: State['global']['waitingPeriod']
) => ({
  type: SET_GLOBAL_WAITING_PERIOD,
  payload,
});

export const setPrime250GlobalRetroActivePeriod = (
  payload: State['global']['retroActivePeriod']
) => ({
  type: SET_GLOBAL_RETRO_ACTIVE_PERIOD,
  payload,
});

export const setPrime250GlobalRetroActivePeriodAsDate = (
  payload: State['global']['retroActivePeriodAsDate']
) => ({
  type: SET_GLOBAL_RETRO_ACTIVE_PERIOD_AS_DATE,
  payload,
});

export const setPrime250Ui = (payload: Partial<State['ui']>) => ({
  type: SET_UI,
  payload,
});

export const setPrime250Coverages = (payload: Partial<State['coverages']>) => ({
  type: SET_COVERAGES,
  payload,
});

export const selectPrime250Coverage = (payload: {
  name: string | number;
  value: boolean;
}) => ({
  type: SELECT_COVERAGE,
  payload,
});

export const setPrime250CoverageLimit = (payload: {
  name: string | number;
  value: number;
}) => ({
  type: SET_COVERAGE_LIMIT,
  payload,
});

export const setPrime250CoverageDeductible = (payload: {
  name: string | number;
  value: number;
}) => ({
  type: SET_COVERAGE_DEDUCTIBLE,
  payload,
});

export const setPrime250CoverageWaitingPeriod = (payload: {
  name: string | number;
  value: number;
}) => ({
  type: SET_COVERAGE_WAITING_PERIOD,
  payload,
});

export const setPrime250CoverageRetroActivePeriod = (payload: {
  name: string | number;
  value: number;
}) => ({
  type: SET_COVERAGE_RETRO_ACTIVE_PERIOD,
  payload,
});

export const setPrime250CoverageRetroActivePeriodDate = (payload: {
  name: string | number;
  value: Date;
}) => ({
  type: SET_COVERAGE_RETRO_ACTIVE_PERIOD_DATE,
  payload,
});

export const setRecalculate = (payload: unknown) => ({
  type: SET_RECALCULATE,
  payload,
});

export const initialState: State = {
  account: {
    id: null,
    revenue: null,
    address1: null,
    address2: null,
    address3: null,
    city: null,
    state: null,
    zipCode: null,
    dunsNumber: null,
    industry: null,
    naicsCode: null,
    name: null,
    yearEstablished: null,

    factors: null,

    surplusOnly: null,
    surplusAvailable: null,

    securityQuestions: {
      lastSecurityAnswered: null,

      // contingent system failure
      dmzSeparation: null,
      thirdPartySecurityAgreement: null,

      // cyber crime loss
      isVerifyingBankAccounts: null,
      isAuthenticatingFundTransferRequests: null,
      isPreventingUnauthorizedWireTransfers: null,

      // full system failure
      backupFrequency: 'SIX_MONTHS',
      testedFullFailover: null,

      // verification if questions were answered
      contingentSystemFailureAnswered: false,
      cyberCrimeLossAnswered: false,
      fullSystemFailureAnswered: false,

      // verification if questions were valid
      contingentSystemFailureValid: false,
      cyberCrimeLossValid: false,
      fullSystemFailureValid: false,

      // verification if questions are required
      contingentSystemFailureRequired: false,
      cyberCrimeLossRequired: false,
      fullSystemFailureRequired: false,

      civilOrCriminalAction: null,
      claimHistory: null,
      isSecurityOfficer: null,
      isSecurityTraining: null,
      lossInBusinessIncome: null,
      pastCyberIncident: null,
      patchingFrequency: null,
      pendingLitigation: null,
      securityBreachRequiringNotification: null,
      useCloudStorage: null,
      useEncryption: null,
    },
  },

  global: {
    preset: 2,
    limit: 1000000,
    deductible: 25000,
    waitingPeriod: 12,
    retroActivePeriod: -1,
    retroActivePeriodAsDate: false,
    minCoverageStartDate: Moment().subtract(7, 'd').toDate(),
    maxCoverageStartDate: Moment().add(75, 'd').toDate(),
    minDate: Moment().endOf('day').add(6, 'months').toDate(),
    minCoverageEndDate: Moment().subtract(3, 'years').endOf('day').toDate(),
    maxCoverageEndDate: Moment().endOf('day').add(18, 'months').toDate(),
    effectiveDate: Moment().endOf('day').add(14, 'days').toDate(),
    endDate: Moment().endOf('day').add(14, 'days').add(1, 'y').toDate(),
  },

  coverages: {},

  ui: {
    shouldRecalculate: 0,
    calculatorError: false,
    initialRender: true,
    preset: 2,
    premium: 0,
    uwReferral: false,
    /** @name limit @deprecated */
    limit: {
      min: 1000000,
      max: 5000000,
    },
    /** @name step @deprecated */
    step: 500000,
    cartTotal: 0,
    loading: false,
    agencyStatus: '',
    /**
     * @name changeType
     * @description Tracks the types of changes occuring
     * @possibleValues 'PRESET', 'GLOBAL', null
     * */
    changeType: DEFAULT_CHANGE_TYPE,
    renewal: false,
    techENOServices: '',
    techENOProducts: '',
  },

  // used by the simple page only
  presets: {
    basic: {
      coverages: [],
      premium: 0,
    },
    popular: {
      coverages: [],
      premium: 0,
    },
    elite: {
      coverages: [],
      premium: 0,
    },
  },
};

export const prime250Reducer = handleActions<State, any>(
  {
    [SET_ACCOUNT]: (state, action) => {
      return {
        ...state,
        account: {
          ...state.account,
          ...action.payload,
          securityQuestions: {
            ...state.account.securityQuestions,
            ...action.payload.securityQuestions,
          },
        },
      };
    },

    [SET_SECURITY_QUESTIONS]: (state, { payload }) => {
      const merged = {
        ...state.account.securityQuestions,
        ...payload,
      };
      const nextCoverages = {} as Record<string, Prime250Coverage>;

      // answereds
      const contingentSystemFailureAnswered =
        !_.isNull(merged.dmzSeparation) &&
        !_.isNull(merged.thirdPartySecurityAgreement);

      const cyberCrimeLossAnswered =
        !_.isNull(merged.isVerifyingBankAccounts) &&
        !_.isNull(merged.isAuthenticatingFundTransferRequests) &&
        !_.isNull(merged.isPreventingUnauthorizedWireTransfers);

      const fullSystemFailureAnswered =
        !_.isNull(merged.backupFrequency) &&
        !_.isNull(merged.dmzSeparation) &&
        !_.isNull(merged.testedFullFailover);

      // valids
      const contingentSystemFailureValid = Boolean(
        merged.dmzSeparation && merged.thirdPartySecurityAgreement
      );

      const cyberCrimeLossValid = Boolean(
        merged.isVerifyingBankAccounts &&
          merged.isAuthenticatingFundTransferRequests &&
          merged.isPreventingUnauthorizedWireTransfers
      );

      const fullSystemFailureValid = Boolean(
        merged.backupFrequency !== 'NEVER' &&
          merged.dmzSeparation &&
          merged.testedFullFailover
      );

      if (contingentSystemFailureAnswered && !contingentSystemFailureValid) {
        nextCoverages[23] = {
          ...state.coverages[23],
          selected: false,
        };
      }

      if (cyberCrimeLossAnswered && !cyberCrimeLossValid) {
        nextCoverages[24] = {
          ...state.coverages[24],
          selected: false,
        };
      }

      if (fullSystemFailureAnswered && !fullSystemFailureValid) {
        nextCoverages[34] = {
          ...state.coverages[34],
          selected: false,
        };
      }

      return {
        ...state,
        account: {
          ...state.account,
          securityQuestions: {
            ...merged,
            contingentSystemFailureAnswered,
            cyberCrimeLossAnswered,
            fullSystemFailureAnswered,
            contingentSystemFailureValid,
            cyberCrimeLossValid,
            fullSystemFailureValid,
          },
        },
        coverages: {
          ...state.coverages,
          ...nextCoverages,
        },
      };
    },

    [SET_GLOBAL]: (state, action) => {
      const nextState = { ...action.payload };

      if (nextState.endDate) {
        nextState.endDate = utcToNativeDate(new Date(nextState.endDate));
      }

      if (nextState.effectiveDate) {
        nextState.effectiveDate = utcToNativeDate(
          new Date(nextState.effectiveDate)
        );
      }

      return {
        ...state,
        global: {
          ...state.global,
          ...nextState,
        },
      };
    },

    [SET_EFFECTIVE_DATE]: (state, action) => {
      const nextDate = action.payload.effectiveDate;

      // * do not update end date, or retro dates, if quote is an endorsement/MTE
      if (state.ui.isEndorsement) {
        return {
          ...state,
          global: {
            ...state.global,
            effectiveDate: nextDate,
            minDate: Moment(nextDate).add(6, 'M').toDate(),
            maxCoverageEndDate: Moment(nextDate).add(18, 'M').toDate(),
          },
        };
      }

      const nextCoverages = Object.entries(state.coverages).reduce(
        (newRecord, currentEntry) => {
          const [key, coverage] = currentEntry;

          // * if available and a custom date is selected, then update the retro date for this coverage
          if (
            coverage.retroActivePeriod?.asDate &&
            coverage.retroActivePeriod?.available &&
            !coverage.retroActivePeriod?.disabled
          ) {
            const nextCoverage = {
              ...coverage,
              retroActivePeriod: {
                ...coverage.retroActivePeriod,
                value: nextDate,
              },
            };

            return { ...newRecord, [key]: nextCoverage };
          }

          return { ...newRecord, [key]: coverage };
        },
        {} as typeof state.coverages
      );

      console.warn('Setting effective date | P250 Reducer ...', {
        coverages: state.coverages,
      });

      return {
        ...state,
        coverages: nextCoverages,
        global: {
          ...state.global,
          ...action.payload,
          endDate: Moment(nextDate).add(1, 'y').toDate(),
          minDate: Moment(nextDate).add(6, 'M').toDate(),
          maxCoverageEndDate: Moment(nextDate).add(18, 'M').toDate(),
        },
      };
    },

    [SET_GLOBAL_PRESET]: (state, action) => {
      const nextCoverages = Object.keys(state.coverages).reduce((acc, id) => {
        const coverage = state.coverages[id];

        if (!_.get(coverage, 'retroActivePeriod.available', false)) {
          return {
            ...acc,
            [id]: coverage,
          };
        }

        return {
          ...acc,
          [id]: {
            ...coverage,
            retroActivePeriod: {
              ...(coverage.retroActivePeriod as CoverageRetroActivePeriod),
              asDate: false,
            },
          },
        };
      }, {});

      return {
        ...state,
        global: {
          ...state.global,
          preset: action.payload,
        },
        coverages: nextCoverages,
      };
    },

    [SET_GLOBAL_AND_RECALCULATE]: (state, action) => {
      return {
        ...state,
        global: {
          ...state.global,
          ...action.payload,
        },
        ui: {
          ...state.ui,
          shouldRecalculate: Moment().unix(),
        },
      };
    },

    [SET_GLOBAL_DEDUCTIBLE]: (state, action) => {
      const nextCoverages = Object.keys(state.coverages).reduce((acc, key) => {
        const coverage = state.coverages[key];
        let nextDeductibleValue = null;

        if (coverage.deductible && coverage.deductible.available) {
          const firstValue = _.get(coverage, 'deductibleOptions[0].value');
          const lastValue = _.get(
            _.last(coverage.deductibleOptions),
            'value',
            ''
          );

          if (action.payload < firstValue) {
            nextDeductibleValue = firstValue;
          } else if (action.payload > lastValue) {
            nextDeductibleValue = lastValue;
          } else {
            nextDeductibleValue = action.payload;
          }
        }

        // If unselected, extortion event deductible should align with the aggregate deductible
        if (coverage._id == 85) {
          if (!coverage.selected) {
            nextDeductibleValue = action.payload;
          } else {
            nextDeductibleValue = coverage.deductible.value;
          }
        }

        return {
          ...acc,
          [key]: {
            ...coverage,
            deductible: {
              ...coverage.deductible,
              value: nextDeductibleValue,
            },
          },
        };
      }, {});

      return {
        ...state,
        global: {
          ...state.global,
          deductible: action.payload,
        },
        coverages: nextCoverages,
        ui: {
          ...state.ui,
          shouldRecalculate: Moment().unix(),
        },
      };
    },

    [SET_GLOBAL_WAITING_PERIOD]: (state, action) => {
      const nextCoverages = Object.keys(state.coverages).reduce((acc, key) => {
        const coverage = state.coverages[key];
        const { waitingPeriod } = coverage;
        let value = null;

        if (waitingPeriod && waitingPeriod.available) {
          const firstValue = _.get(coverage, 'waitingPeriodOptions[0].value');
          const lastValue = _.last(coverage.waitingPeriodOptions)?.value;

          if (action.payload < firstValue) {
            value = firstValue;
            // @ts-expect-error fixing this ts error may cause differing behavior
          } else if (action.payload > lastValue) {
            value = lastValue;
          } else {
            value = action.payload;
          }
        }

        return {
          ...acc,
          [key]: {
            ...coverage,
            waitingPeriod: {
              ...waitingPeriod,
              value,
            },
          },
        };
      }, {});

      return {
        ...state,
        global: {
          ...state.global,
          waitingPeriod: action.payload,
        },
        coverages: nextCoverages,
        ui: {
          ...state.ui,
          shouldRecalculate: Moment().unix(),
        },
      };
    },

    [SET_GLOBAL_RETRO_ACTIVE_PERIOD]: (state, action) => {
      const changeableRetrosOrdinals = [0, 1, 2];
      const changableRetros = _.pick(state.coverages, changeableRetrosOrdinals);
      const nextCoverages = Object.keys(changableRetros).reduce((acc, key) => {
        const coverage = state.coverages[key];
        const { retroActivePeriod } = coverage;

        return {
          ...acc,
          [key]: {
            ...coverage,
            retroActivePeriod: {
              ...retroActivePeriod,
              value: action.payload,
              asDate: false,
            },
          },
        };
      }, {} as State['coverages']);

      return {
        ...state,
        global: {
          ...state.global,
          retroActivePeriod: action.payload,
          retroActivePeriodAsDate: false,
        },
        coverages: {
          ...state.coverages,
          ...nextCoverages,
          30: {
            ...state.coverages[30],
            retroActivePeriod: {
              ...state.coverages[30].retroActivePeriod,
              ...nextCoverages[2].retroActivePeriod,
              asDate: false,
            },
          },
          31: {
            ...state.coverages[31],
            retroActivePeriod: {
              ...state.coverages[31].retroActivePeriod,
              ...nextCoverages[2].retroActivePeriod,
              asDate: false,
            },
          },
          33: {
            ...state.coverages[33],
            retroActivePeriod: {
              ...state.coverages[33].retroActivePeriod,
              ...nextCoverages[0].retroActivePeriod,
              asDate: false,
            },
          },
        },
        ui: {
          ...state.ui,
          shouldRecalculate: Moment().unix(),
        },
      };
    },

    [SET_GLOBAL_RETRO_ACTIVE_PERIOD_AS_DATE]: (state, action) => {
      const changeableRetrosOrdinals = [0, 1, 2, 14, 37];
      const nextCoverages = changeableRetrosOrdinals.reduce((acc, key) => {
        const coverage = state.coverages[key];
        const { retroActivePeriod } = coverage;

        return {
          ...acc,
          [key]: {
            ...coverage,
            retroActivePeriod: {
              ...retroActivePeriod,
              value: action.payload,
              asDate: true,
            },
          },
        };
      }, {} as State['coverages']);

      return {
        ...state,
        global: {
          ...state.global,
          retroActivePeriod: action.payload,
          retroActivePeriodAsDate: true,
        },
        ui: {
          ...state.ui,
          shouldRecalculate: Moment().unix(),
        },
        coverages: {
          ...state.coverages,
          ...nextCoverages,
          30: {
            ...state.coverages[30],
            retroActivePeriod: {
              ...state.coverages[30].retroActivePeriod,
              ...nextCoverages[2].retroActivePeriod,
            },
          },
          31: {
            ...state.coverages[31],
            retroActivePeriod: {
              ...state.coverages[31].retroActivePeriod,
              ...nextCoverages[2].retroActivePeriod,
            },
          },
          33: {
            ...state.coverages[33],
            retroActivePeriod: {
              ...state.coverages[33].retroActivePeriod,
              ...nextCoverages[0].retroActivePeriod,
            },
          },
        },
      };
    },

    [SET_GLOBAL_RETRO_ACTIVE_PERIOD_DATE_NO_SIDE_EFFECTS]: (state, action) => {
      return {
        ...state,
        global: {
          ...state.global,
          retroActivePeriod: action.payload,
          retroActivePeriodAsDate: true,
        },
        ui: {
          ...state.ui,
          shouldRecalculate: Moment().unix(),
        },
      };
    },

    [SET_GLOBAL_RETRO_ACTIVE_PERIOD_NO_SIDE_EFFECTS]: (state, action) => {
      return {
        ...state,
        global: {
          ...state.global,
          retroActivePeriod: action.payload,
          retroActivePeriodAsDate: false,
        },
        ui: {
          ...state.ui,
          shouldRecalculate: Moment().unix(),
        },
      };
    },

    [SET_COVERAGES]: (state, action) => {
      const {
        account: { securityQuestions },
      } = state;
      const nextCoverages = {
        ...action.payload,
      };
      const nextSecurityQuestions = {
        ...securityQuestions,
      };

      if (
        securityQuestions.contingentSystemFailureAnswered &&
        !securityQuestions.contingentSystemFailureValid
      ) {
        nextCoverages[23] = {
          ...state.coverages[23],
          ...action.payload[23],
          selected: false,
        };
      }

      if (
        securityQuestions.cyberCrimeLossAnswered &&
        !securityQuestions.cyberCrimeLossValid
      ) {
        nextCoverages[24] = {
          ...action.payload[24],
          ...state.coverages[24],
          selected: false,
        };
      }

      if (
        securityQuestions.fullSystemFailureAnswered &&
        !securityQuestions.fullSystemFailureValid
      ) {
        nextCoverages[34] = {
          ...action.payload[34],
          ...state.coverages[34],
          selected: false,
        };
      }

      if (
        typeof nextCoverages[23] !== 'undefined' &&
        nextCoverages[23].available &&
        nextCoverages[23].selected
      ) {
        nextSecurityQuestions.contingentSystemFailureRequired = true;
      }

      if (
        typeof nextCoverages[24] !== 'undefined' &&
        nextCoverages[24].available &&
        nextCoverages[24].selected
      ) {
        nextSecurityQuestions.cyberCrimeLossRequired = true;
      }

      if (
        typeof nextCoverages[34] !== 'undefined' &&
        nextCoverages[34].available &&
        nextCoverages[34].selected
      ) {
        nextSecurityQuestions.fullSystemFailureRequired = true;
      }

      return {
        ...state,
        account: {
          ...state.account,
          securityQuestions: {
            ...securityQuestions,
            ...nextSecurityQuestions,
          },
        },
        coverages: nextCoverages,
        ui: {
          ...state.ui,
          shouldRecalculate: Moment().unix(),
        },
      };
    },

    [SELECT_COVERAGE]: (state, action) => {
      const id = action.payload.name;
      const securityQuestions = {
        ...state.account.securityQuestions,
      };

      if (id == 23) {
        securityQuestions.contingentSystemFailureRequired =
          action.payload.value;
      }

      if (id == 24) {
        securityQuestions.cyberCrimeLossRequired = action.payload.value;
      }

      if (id == 34) {
        securityQuestions.fullSystemFailureRequired = action.payload.value;
      }

      return {
        ...state,
        account: {
          ...state.account,
          securityQuestions,
        },
        coverages: {
          ...state.coverages,
          [id]: {
            ...state.coverages[id],
            selected: action.payload.value,
          },
        },
        ui: {
          ...state.ui,
          shouldRecalculate: Moment().unix(),
        },
        global: {
          ...state.global,
          preset: -1,
        },
      };
    },

    [SET_COVERAGE_LIMIT]: (state, action) => {
      const id = action.payload.name;

      return {
        ...state,
        coverages: {
          ...state.coverages,
          [id]: {
            ...state.coverages[id],
            limit: {
              ...state.coverages[id].limit,
              value: action.payload.value,
            },
          },
        },
        ui: {
          ...state.ui,
          shouldRecalculate: Moment().unix(),
        },
        global: {
          ...state.global,
          preset: -1,
        },
      };
    },

    [SET_COVERAGE_DEDUCTIBLE]: (state, action) => {
      const [id] = action.payload.name.split('.');

      return {
        ...state,
        coverages: {
          ...state.coverages,
          [id]: {
            ...state.coverages[id],
            deductible: {
              ...state.coverages[id].deductible,
              value: action.payload.value,
            },
          },
        },
        ui: {
          ...state.ui,
          shouldRecalculate: Moment().unix(),
        },
        global: {
          ...state.global,
          preset: -1,
        },
      };
    },

    [SET_COVERAGE_WAITING_PERIOD]: (state, action) => {
      const [id] = action.payload.name.split('.');

      return {
        ...state,
        coverages: {
          ...state.coverages,
          [id]: {
            ...state.coverages[id],
            waitingPeriod: {
              ...state.coverages[id].waitingPeriod,
              value: action.payload.value,
            },
          },
        },
        ui: {
          ...state.ui,
          shouldRecalculate: Moment().unix(),
        },
        global: {
          ...state.global,
          preset: -1,
        },
      };
    },

    [SET_COVERAGE_RETRO_ACTIVE_PERIOD]: (state, action) => {
      const [id] = action.payload.name.split('.');

      return {
        ...state,
        coverages: {
          ...state.coverages,
          [id]: {
            ...state.coverages[id],
            retroActivePeriod: {
              ...state.coverages[id].retroActivePeriod,
              value: action.payload.value,
              asDate: false,
            },
          },
          ...syncRetroAsOption(
            0,
            33,
            id,
            action.payload.value,
            state.coverages
          ),
          ...syncRetroAsOption(
            0,
            37,
            id,
            action.payload.value,
            state.coverages
          ),
          ...syncRetroAsOption(
            2,
            30,
            id,
            action.payload.value,
            state.coverages
          ),
          ...syncRetroAsOption(
            2,
            31,
            id,
            action.payload.value,
            state.coverages
          ),
        },
        ui: {
          ...state.ui,
          shouldRecalculate: Moment().unix(),
        },
        global: {
          ...state.global,
          preset: -1,
        },
      };
    },

    [SET_COVERAGE_RETRO_ACTIVE_PERIOD_DATE]: (state, action) => {
      return {
        ...state,
        coverages: {
          ...state.coverages,
          [action.payload.name]: {
            ...state.coverages[action.payload.name],
            retroActivePeriod: {
              ...state.coverages[action.payload.name].retroActivePeriod,
              value: Moment(action.payload.value).unix() * 1000,
              asDate: true,
            },
          },
          ...syncRetroAsDate(
            0,
            33,
            action.payload.name,
            action.payload.value,
            state.coverages
          ),
          ...syncRetroAsDate(
            0,
            37,
            action.payload.name,
            action.payload.value,
            state.coverages
          ),
          ...syncRetroAsDate(
            2,
            30,
            action.payload.name,
            action.payload.value,
            state.coverages
          ),
          ...syncRetroAsDate(
            2,
            31,
            action.payload.name,
            action.payload.value,
            state.coverages
          ),
        },
        ui: {
          ...state.ui,
          shouldRecalculate: Moment().unix(),
        },
        global: {
          ...state.global,
          preset: -1,
        },
      };
    },

    [SET_UI]: (state, action) => {
      return {
        ...state,
        ui: {
          ...state.ui,
          ...action.payload,
        },
      };
    },

    [SET_RECALCULATE]: (state) => {
      return {
        ...state,
        ui: {
          ...state.ui,
          shouldRecalculate: Moment().unix(),
        },
      };
    },
  },
  initialState
);

function syncRetroAsOption(
  leaderId: number,
  followerId: number,
  id: number,
  value: number,
  coverages: State['coverages']
) {
  return (
    // eslint-disable-next-line
    leaderId == id
      ? {
          [followerId]: {
            ...coverages[followerId],
            retroActivePeriod: {
              ...coverages[followerId].retroActivePeriod,
              value,
              asDate: false,
            },
          },
        }
      : {}
  );
}

function syncRetroAsDate(
  leaderId: number,
  followerId: number,
  id: number,
  value: Date,
  coverages: State['coverages']
) {
  return (
    // eslint-disable-next-line
    leaderId == id
      ? {
          [followerId]: {
            ...coverages[followerId],
            retroActivePeriod: {
              ...coverages[followerId].retroActivePeriod,
              value: Moment(value).unix() * 1000,
              asDate: true,
            },
          },
        }
      : {}
  );
}
