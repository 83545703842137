import type { CountryCode } from 'libphonenumber-js';
import type { valueOf } from '../types/common/utility';

export const languages = Object.freeze({
  'en-US': 'en-US',
  'en-GB': 'en-GB',
});

export type Languages = valueOf<typeof languages>;

export const COUNTRIES = Object.freeze({
  US: 'US',
  UK: 'UK',
});

export const COUNTRY_NAME_MAP: Record<string, string> = {
  'UNITED STATES': COUNTRIES.US,
  USA: COUNTRIES.US,
  US: COUNTRIES.US,
  'UNITED STATES OF AMERICA': COUNTRIES.US,
  AMERICA: COUNTRIES.US,
  'UNITED KINGDOM': COUNTRIES.UK,
  UK: COUNTRIES.UK,
  'GREAT BRITAIN': COUNTRIES.UK,
  GB: COUNTRIES.UK,
  BRITAIN: COUNTRIES.UK,
  ENGLAND: COUNTRIES.UK,
};

export const getAbbreviatedCountry = (countryName: string) => {
  const upperCaseCountryName = countryName.toUpperCase();
  return COUNTRY_NAME_MAP[upperCaseCountryName] as Countries;
};

export type Countries = (typeof COUNTRIES)[keyof typeof COUNTRIES];

export const REGION_FLAGS = {
  [languages['en-US']]: '🇺🇸',
  [languages['en-GB']]: '🇬🇧',
};

export const REGION_OPTIONS = [
  {
    label: `US`,
    value: languages['en-US'],
    flag: REGION_FLAGS['en-US'],
  },
  {
    label: `UK`,
    value: languages['en-GB'],
    flag: REGION_FLAGS['en-GB'],
  },
] as const;

export const COUNTRY_MAPPING = Object.freeze({
  UK: languages['en-GB'],
  'United Kingdom': languages['en-GB'],
  'UNITED KINGDOM': languages['en-GB'],
  US: languages['en-US'],
  'United States': languages['en-US'],
  'UNITED STATES': languages['en-US'],
});

export const LANGUAGE_COUNTRY_MAPPING = Object.freeze({
  [languages['en-GB']]: 'UK',
  [languages['en-US']]: 'US',
});

export const REGION_COUNTRY_CODE_MAPPING = Object.freeze({
  [languages['en-GB']]: '+44',
  [languages['en-US']]: '+1',
});

export type CountryCodes =
  (typeof REGION_COUNTRY_CODE_MAPPING)[keyof typeof REGION_COUNTRY_CODE_MAPPING];

/* Country Code Selector */
export const COUNTRY_CODES_SELECT_OPTIONS = [
  {
    value: REGION_COUNTRY_CODE_MAPPING['en-US'],
    label: `United States (+1)`,
  },
  {
    value: REGION_COUNTRY_CODE_MAPPING['en-GB'],
    label: `United Kingdom (+44)`,
  },
] as const;

export const INDUSTRY_CODE_PATH = {
  [COUNTRIES.UK]: 'sicCode',
  [COUNTRIES.US]: 'naicsCode',
} as const;

export const getIndustryCodePath = (country: Countries) => {
  return INDUSTRY_CODE_PATH[country] || INDUSTRY_CODE_PATH.US;
};

export const INDUSTRY_DESCRIPTION_PATH = {
  [COUNTRIES.UK]: {
    PRIMARY: 'sicPrimaryDesc',
    SECONDARY: 'sicSecondaryDesc',
  },
  [COUNTRIES.US]: {
    PRIMARY: 'primaryIndustry',
    SECONDARY: 'secondaryIndustry',
  },
} as const;

export const getIndustryPrimaryDescriptionPath = (country: Countries) => {
  if (!country) {
    return INDUSTRY_DESCRIPTION_PATH.US.PRIMARY;
  }

  return (
    INDUSTRY_DESCRIPTION_PATH[country]?.PRIMARY ||
    INDUSTRY_DESCRIPTION_PATH.US.PRIMARY
  );
};

export const getIndustrySecondaryDescriptionPath = (country: Countries) => {
  if (!country) {
    return INDUSTRY_DESCRIPTION_PATH.US.SECONDARY;
  }

  return (
    INDUSTRY_DESCRIPTION_PATH[country]?.SECONDARY ||
    INDUSTRY_DESCRIPTION_PATH.US.SECONDARY
  );
};

export const COUNTRY_BY_COUNTRY_CODE = Object.freeze({
  [REGION_COUNTRY_CODE_MAPPING['en-GB']]: languages['en-GB'],
  [REGION_COUNTRY_CODE_MAPPING['en-US']]: languages['en-US'],
});

export const deriveLanguageFromCountryCode = (
  countryCode: CountryCodes | undefined
) => {
  if (!countryCode) {
    return null;
  }

  return COUNTRY_BY_COUNTRY_CODE[countryCode] ?? null;
};

export const INTERNATIONAL_PHONE_COUNTRY = {
  [languages['en-GB']]: 'GB',
  [languages['en-US']]: 'US',
} as Record<string, CountryCode>;
