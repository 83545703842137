export type ImageMapping = {
  light: string;
  dark: string;
};

export type ImageMappings = {
  MICROSOFT: ImageMapping;
  AWS: ImageMapping;
  GOOGLE: ImageMapping;
  QUALYS: ImageMapping;
  SPLUNK: ImageMapping;
  TENABLE: ImageMapping;
  ZIMPERIUM: ImageMapping;
  AZURE: ImageMapping;
  FICO: ImageMapping;
  MOBILEIRON: ImageMapping;
  PALOALTO: ImageMapping;
  RAPID7: ImageMapping;
  SENTINEL: ImageMapping;
  SECURITYSTUDIO: ImageMapping;
  QUALYSPC: ImageMapping;
  QUALYSVM: ImageMapping;
  QUALYSCS: ImageMapping;
  FORTINET: ImageMapping;
  WIZER: ImageMapping;
  SAFEGUARD: ImageMapping;
  PROOFPOINT: ImageMapping;
  SECUREWORKS: ImageMapping;
  GOOGLEWORKSPACE: ImageMapping;
  GSC: ImageMapping;
  CLOUDFLARE: ImageMapping;
  SOPHOS: ImageMapping;
  SNYK: ImageMapping;
  CROWDSTRIKE: ImageMapping;
  CROWDSTRIKE_SPOTLIGHT: ImageMapping;
  MS_DEFENDER_ENDPOINT: ImageMapping;
  MS_DEFENDER_VMS: ImageMapping;
  INSIGHTVM: ImageMapping;
  SEMGREP: ImageMapping;
  MS_DEFENDER_CLOUD: ImageMapping;
  SENTINELONE_VMS: ImageMapping;
  AWS_SECURITYHUB: ImageMapping;
  SENTINELONE: ImageMapping;
  LACEWORK_CSPM: ImageMapping;
  MS_ENTRA: ImageMapping;
  AIKIDO: ImageMapping;
  OKTA_IDP: ImageMapping;
  ARNICA: ImageMapping;
  AWS_INSPECTOR2: ImageMapping;
  default: ImageMapping;
  [key: string]: ImageMapping;
};

export const imageMappings: ImageMappings = {
  MICROSOFT: {
    light: 'images/Microsoft365B.svg',
    dark: 'images/Microsoft365B.svg',
  },
  AWS: {
    light: 'images/AWSW.svg',
    dark: 'images/AWSB.svg',
  },
  GOOGLE: {
    light: 'images/GoogleCloudB.svg',
    dark: 'images/GoogleCloudB.svg',
  },
  QUALYS: {
    light: 'images/QualysW.svg',
    dark: 'images/QualysB.svg',
  },
  SPLUNK: {
    light: 'images/SplunkW.svg',
    dark: 'images/SplunkB.svg',
  },
  TENABLE: {
    light: 'images/TenableB.svg',
    dark: 'images/TenableB.svg',
  },
  ZIMPERIUM: {
    light: 'images/ZimperiumB.svg',
    dark: 'images/ZimperiumB.svg',
  },

  AZURE: {
    light: 'images/AzureB.svg',
    dark: 'images/AzureB.svg',
  },
  FICO: {
    light: 'images/FicoB.svg',
    dark: 'images/FicoB.svg',
  },
  MOBILEIRON: {
    light: 'images/MobileIronLight.svg',
    dark: 'images/MobileIronB.svg',
  },
  PALOALTO: {
    light: 'images/PaloAltoLight.svg',
    dark: 'images/PaloAltoB.svg',
  },
  RAPID7: {
    light: 'images/Rapid7L.svg',
    dark: 'images/Rapid7D.svg',
  },
  SENTINEL: {
    light: 'images/AzureSentinel.svg',
    dark: 'images/AzureSentinel.svg',
  },
  SECURITYSTUDIO: {
    light: 'images/securityStudio_light.svg',
    dark: 'images/securityStudio_dark.svg',
  },
  QUALYSPC: {
    light: 'images/Qualys.svg',
    dark: 'images/Qualys.svg',
  },
  QUALYSVM: {
    light: 'images/Qualys.svg',
    dark: 'images/Qualys.svg',
  },
  QUALYSCS: {
    light: 'images/Qualys.svg',
    dark: 'images/Qualys.svg',
  },
  FORTINET: {
    light: 'images/Fortinet_logo_Black.svg',
    dark: 'images/Fortinet_logo_White.svg',
  },
  WIZER: {
    light: 'images/Wizer_LightLogo.svg',
    dark: 'images/Wizer_DarkLogo.svg',
  },
  SAFEGUARD: {
    light: 'images/SafeguardLogo_Black.svg',
    dark: 'images/SafeguardLogo_White.svg',
  },
  PROOFPOINT: {
    light: 'images/ProofPoint_Black.svg',
    dark: 'images/ProofPoint_White.svg',
  },
  SECUREWORKS: {
    light: 'images/secureworks_icon.png',
    dark: 'images/secureworks_icon.png',
  },
  GOOGLEWORKSPACE: {
    light: 'images/GoogleWorkspace.svg',
    dark: 'images/GoogleWorkspace.svg',
  },
  GSC: {
    light: 'images/connectors/GSC.svg',
    dark: 'images/connectors/GSC.svg',
  },
  CLOUDFLARE: {
    light: 'images/connectors/Cloudflare.svg',
    dark: 'images/connectors/Cloudflare.svg',
  },
  SOPHOS: {
    light: 'images/connectors/SophosCardLogo.svg',
    dark: 'images/connectors/SophosCardLogo.svg',
  },
  SNYK: {
    light: 'images/connectors/Snyk_Light.svg',
    dark: 'images/connectors/Snyk_Dark.svg',
  },
  CROWDSTRIKE: {
    light: 'images/connectors/CrowdStrike_Light.svg',
    dark: 'images/connectors/CrowdStrike_Light.svg',
  },
  CROWDSTRIKE_SPOTLIGHT: {
    light: 'images/connectors/CrowdStrike_Light.svg',
    dark: 'images/connectors/CrowdStrike_Light.svg',
  },
  MS_DEFENDER_ENDPOINT: {
    light: 'images/connectors/MSDefender.svg',
    dark: 'images/connectors/MSDefender.svg',
  },
  MS_DEFENDER_VMS: {
    light: 'images/connectors/MSDefender.svg',
    dark: 'images/connectors/MSDefender.svg',
  },
  INSIGHTVM: {
    light: 'images/connectors/INSIGHTVM.png',
    dark: 'images/connectors/INSIGHTVM.png',
  },
  SEMGREP: {
    light: 'images/connectors/semgrep.svg',
    dark: 'images/connectors/semgrep.svg',
  },
  MS_DEFENDER_CLOUD: {
    light: 'images/connectors/MSDefender.svg',
    dark: 'images/connectors/MSDefender.svg',
  },
  SENTINELONE_VMS: {
    light: 'images/connectors/Sentinel.png',
    dark: 'images/connectors/Sentinel.png',
  },
  AWS_SECURITYHUB: {
    light: 'images/connectors/AWSSecurityHub.jpg',
    dark: 'images/connectors/AWSSecurityHub.jpg',
  },
  SENTINELONE: {
    light: 'images/connectors/Sentinel.png',
    dark: 'images/connectors/Sentinel.png',
  },
  LACEWORK_CSPM: {
    light: 'images/connectors/Lacework.svg',
    dark: 'images/connectors/Lacework.svg',
  },
  MS_ENTRA: {
    light: 'images/connectors/MSEntra.svg',
    dark: 'images/connectors/MSEntra.svg',
  },
  OKTA_IDP: {
    light: 'images/connectors/Okta.png',
    dark: 'images/connectors/Okta.png',
  },
  ARNICA: {
    light: 'images/connectors/Arnica.png',
    dark: 'images/connectors/Arnica.png',
  },
  AWS_INSPECTOR2: {
    light: 'images/connectors/AWSInspector.png',
    dark: 'images/connectors/AWSInspector.png',
  },
  AIKIDO: {
    light: 'images/DefaultConnector.svg',
    dark: 'images/DefaultConnector.svg',
  },
  default: {
    light: 'images/DefaultConnector.svg',
    dark: 'images/DefaultConnector.svg',
  },
};
