import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
// mui
import { Box, CircularProgress as MuiCircularProgress } from '@mui/material';
import { withStyles } from '@mui/styles';

import { deleteConnection, getConnections } from './ConnectorService';
import { PubSub } from '../../utils/eventUtils';

let timeoutId = null;

const MSConnectorConfig = ({ classes, connectorType }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [myData, setMyData] = useState(null);
  const [deleting, setDeleting] = useState(null);
  const [waiting, setWaiting] = useState(null);

  useEffect(() => {
    Promise.all([getConnections({})])
      .then((responses) => {
        const data = responses[0].data.content;
        const connected = Array.isArray(data)
          ? data.filter((item) => item.connectorType === 'MICROSOFT')
          : [];
        const temp = {};
        connected.forEach((item) => {
          temp[item.connectorId] = false;
        });
        setDeleting(temp);
        setWaiting(temp);
        setMyData({
          connected,
        });
      })
      .catch(console.error.bind(console));
  }, [connectorType]);

  const shareData = {
    MICROSOFT: {
      title: 'Microsoft Secure Score',
      text1: 'Tenant ID: ',
      id: 'azureTenantId',
    },
    GOOGLE: {
      title: 'Google Command Center',
      text1: 'Organization ID: ',
      id: 'organizationId',
    },
  };

  const handleIconClick = (connectorId) => () => {
    setDeleting((prevState) => {
      return {
        ...prevState,
        [connectorId]: true,
      };
    });
  };

  const handleDelete = (connectorId) => () => {
    setWaiting((prevState) => {
      return {
        ...prevState,
        [connectorId]: true,
      };
    });

    deleteConnection({ id: connectorId })
      .then(() => {
        timeoutId = window.setTimeout(() => {
          enqueueSnackbar('Successfully removed the connector', {
            variant: 'success',
          });
          PubSub.publish('connector-page-refetch', null);
          setWaiting(false);
          window.clearTimeout(timeoutId);
          setMyData({ connected: [] });
        }, 5000);
      })
      .catch((error) => {
        setWaiting((prevState) => {
          return {
            ...prevState,
            [connectorId]: false,
          };
        });
        console.error.bind(console)('error', error);
      });
  };

  const handleCancel = (connectorId) => () => {
    setDeleting((prevState) => {
      return {
        ...prevState,
        [connectorId]: false,
      };
    });
  };

  const renderConnectors = () => {
    const result = [];
    for (const item of myData.connected) {
      const { id: connectorId, connectorStatus } = item;
      const element = (
        <section className={classes.itemContainer}>
          <p className={classes.itemTitle}>{shareData[connectorType].title}</p>
          <p className={classes.tenant}>
            {shareData[connectorType].text1}{' '}
            <span className={classes.tenantId}>
              {item.metadata?.azureTenantId}
            </span>
          </p>
          <p className={classes.status}>
            Status:{' '}
            <span className={classes.statusValue}>
              {connectorStatus === 'SUCCESS' ? 'Connected' : 'Not Connected'}
            </span>
          </p>
          <section className={classes.buttonsContainer}>
            {!deleting[connectorId] ? (
              <Box
                component="img"
                src="images/cancel.svg"
                alt=""
                className={classes.cancelIcon}
                onClick={handleIconClick(connectorId)}
              />
            ) : null}
            {deleting[connectorId] ? (
              <section className={classes.buttons}>
                <Box
                  className={classes.NoBtn}
                  onClick={handleCancel(connectorId)}
                >
                  CANCEL
                </Box>
                <Box
                  className={classes.yesBtn}
                  onClick={handleDelete(connectorId)}
                >
                  {waiting[connectorId] ? (
                    <section className={classes.savingContainer}>
                      <MuiCircularProgress
                        size={14}
                        style={{
                          marginRight: 7,
                          position: 'absolute',
                        }}
                      />
                      <span className={classes.saving}>YES</span>
                    </section>
                  ) : (
                    <>DELETE</>
                  )}
                </Box>
              </section>
            ) : null}
          </section>
        </section>
      );
      result.push(element);
    }
    return result;
  };

  if (myData) {
    return (
      <section className={classes.mainContainer}>{renderConnectors()}</section>
    );
  }
  return <div className={classes.loading}>Loading...</div>;
};

const styles = ({ palette }) => {
  return {
    loading: {
      textAlign: 'center',
      margin: 50,
    },
    buttonsContainer: {
      width: '9.5rem',
      height: '2.5rem',
      marginRight: 10,
    },
    NoBtn: {
      width: '5rem',
      height: '1.75rem',
      borderRadius: 5,
      backgroundColor: palette.background.red,
      marginRight: 10,
      fontSize: '1rem',
      fontWeight: 600,
      lineHeight: 1.33,
      color: palette.primary.main,
      textAlign: 'center',
      padding: 3,
      cursor: 'pointer',
    },
    yesBtn: {
      width: '5rem',
      height: '1.75rem',
      borderRadius: 5,
      backgroundColor: palette.background.button,
      fontSize: '1rem',
      fontWeight: 600,
      lineHeight: 1.33,
      color: palette.primary.main,
      textAlign: 'center',
      padding: 3,
      cursor: 'pointer',
    },
    statusValue: {
      fontWeight: 'normal !important',
    },
    status: {
      fontSize: '1.167rem',
      fontWeight: 600,
      lineHeight: 1.5,
      color: palette.text.primary,
      margin: '0 !important',
    },
    itemContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      backgroundColor: palette.background.lighter,
      padding: '8px 0 0 10px',
      marginBottom: 5,
    },
    buttons: {
      display: 'flex',
      flexDirection: 'row',
    },
    cancelIcon: {
      width: '1.667rem',
      height: '1.667rem',
      textAlign: 'center',
      margin: '0 20px',
      cursor: 'pointer',
    },
    tenant: {
      fontSize: '1.167rem',
      fontWeight: 600,
      lineHeight: 1.5,
      color: palette.text.primary,
      margin: '0 !important',
    },
    tenantId: {
      fontWeight: 'normal !important',
    },
    itemTitle: {
      fontSize: '1.167rem',
      fontWeight: 600,
      lineHeight: 1.5,
      color: palette.text.primary,
      margin: '0 !important',
    },
    iconStyle: {
      width: '10rem',
      height: '5rem',
      textAlign: 'center',
      margin: '20px 0 0 -40px',
    },
    mainContainer: {},
    availableButton: {
      width: '7.5rem',
      height: '1.667rem',
      borderRadius: 5,
      backgroundColor: palette.background.button,
      color: palette.primary.main,
      fontSize: '1.083rem',
      lineHeight: 1.33,
      margin: '0 0 15px 0',
      paddingTop: 2,
      textTransform: 'uppercase',
      cursor: 'pointer',
      textAlign: 'center',
    },
  };
};

export default withStyles(styles, { withTheme: true })(MSConnectorConfig);
