import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFilePowerpoint,
  faEnvelope,
  faFile,
} from '@fortawesome/pro-solid-svg-icons';

import PdfFileIcon from '../../_assets/svg/docs/PdfFileIcon.svg';
import MSWordIcon from '../../_assets/svg/docs/MSWordIcon.svg';
import MSExcelIcon from '../../_assets/svg/docs/MSExcelIcon.svg';
import CSVIcon from '../../_assets/svg/docs/CSVIcon.svg';
import JPGIcon from '../../_assets/svg/docs/JPGIcon.svg';
import PNGIcon from '../../_assets/svg/docs/PNGIcon.svg';
import GIFIcon from '../../_assets/svg/docs/GIFIcon.svg';

export const useFileTypeIcons = (
  extension = '',
  style: Record<string, unknown> = {
    height: '2rem',
    width: '2rem',
    marginRight: '0.5rem',
  }
) => {
  switch (extension.toLowerCase()) {
    case 'pdf':
      return <PdfFileIcon style={style} />;
    case 'doc':
    case 'docx':
      return <MSWordIcon style={style} />;
    case 'xls':
    case 'xlsx':
      return <MSExcelIcon style={style} />;
    case 'csv':
      return <CSVIcon style={style} />;
    case 'png':
      return <PNGIcon style={style} />;
    case 'jpg':
    case 'jpeg':
      return <JPGIcon style={style} />;
    case 'gif':
      return <GIFIcon style={style} />;
    case 'ppt':
    case 'pptx':
      return (
        <FontAwesomeIcon style={style} size="lg" icon={faFilePowerpoint} />
      );
    case 'eml':
      return <FontAwesomeIcon style={style} size="lg" icon={faEnvelope} />;
    default:
      return <FontAwesomeIcon style={style} size="lg" icon={faFile} />;
  }
};
